import { ReactNode } from 'react';

import { Box } from 'components/Box';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';

type Props = {
  href: string;
  children: ReactNode | null | undefined;
};

export function CmsStickyHeaderLinksBlockExternalLink({
  href,
  children,
}: Props) {
  return (
    <Button type="link" href={href} target="_blank">
      {children}
      <Box transform="translateY(1px)" display="inline-block" ml="6px">
        <Icon name="arrow-up-right" size={12} />
      </Box>
    </Button>
  );
}

import styled, { css } from 'styled-components';

import { Box } from 'components/Box';
import { Hide } from 'components/Hide';
import { Icon } from 'components/Icon/Icon';
import { LegacyButton } from 'components/LegacyButton/LegacyButton';
import { BodySmall } from 'components/Text/BodySmall';
import { H3 } from 'components/Text/H3';
import { H4 } from 'components/Text/H4';
import { CmsApiStickyHeaderLinkData } from 'modules/cms/api/types/blocks/CmsApiStickyHeaderLinksBlock';
import { colors, cssBreakpoints, transition } from 'theme/theme';
import { scrollIntoView } from 'utils/scrollIntoView';

const IconContainer = styled(Box)`
  transition: all ${transition};

  * {
    fill: currentcolor;
  }
`;

function HeaderLinkTitle({ isSticky, ...props }: { isSticky: boolean }) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/jsx-props-no-spreading
  return isSticky ? <H4 {...props} /> : <H3 {...props} />;
}

export const CmsStickyHeaderLinksItem = styled(
  ({
    className,
    link,
    isSticky,
  }: {
    className?: string;
    link: CmsApiStickyHeaderLinkData;
    isSticky: boolean;
  }) => {
    const onClick = () => {
      const element = document.getElementById(link.url);

      if (element) {
        scrollIntoView(element);
      } else {
        window.location.href = `#${link.url}`;
      }
    };

    return (
      <li className={className} key={`${isSticky ? 'sticky-' : ''}${link.url}`}>
        <LegacyButton onClick={onClick}>
          {/* @ts-expect-error TS(2322): Type '{ children: any; tag: string; isSticky: any;... Remove this comment to see the full error message */}
          <HeaderLinkTitle tag="div" isSticky={isSticky} lineHeight={1.25}>
            {link.heading}
          </HeaderLinkTitle>

          <Hide xs sm md>
            {!isSticky && link.subHeading && link.subHeading.length > 0 && (
              <BodySmall>{link.subHeading}</BodySmall>
            )}
            <IconContainer mt={isSticky ? undefined : '16px'}>
              <Icon name="arrow-down" size={14} />
            </IconContainer>
          </Hide>
        </LegacyButton>
      </li>
    );
  },
)`
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;

  button {
    transition: all ${transition};

    &:hover,
    &:focus {
      color: ${colors.brandBlue};

      ${IconContainer} {
        transform: translateY(6px);
      }
    }
  }

  ${({ isSticky }) =>
    isSticky
      ? css`
          margin: 0 24px;
          height: 104px;
          border: none;

          ${IconContainer} {
            position: absolute;
            bottom: 6px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        `
      : css`
          @media all and (max-width: ${cssBreakpoints.lgDown}) {
            margin-top: 12px;

            &:last-child {
              margin-bottom: 12px;
            }
          }

          @media all and (min-width: ${cssBreakpoints.mdUp}) {
            width: calc(
              100% /
                ${(props) =>
                  /* @ts-expect-error total is missing from props */
                  props.total}
            );
            height: 175px;

            button {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
              height: 100%;
              padding: 20px 20px 32px;
            }

            &:not(:first-child) {
              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: calc(100% - 48px);
                background: ${colors.selectionGrey};
              }
            }

            h3 {
              font-size: 22px;
            }
          }
        `}
`;
